import React, { useEffect, useRef,useState } from "react"
import styled from "styled-components"
import Accueil from "./Accueil"
import Filtres from "./Filtres"
import Drmc from "./Videos_Index/Drmc"
import GreenCode from "./Videos_Index/GreenCode"
import Iketchak from "./Videos_Index/Iketchak"
import Ombrea from "./Videos_Index/Ombrea"
import Scic from "./Videos_Index/Scic"
import Sorgalu from "./Videos_Index/Sorgalu"

import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import ProgressGsap from "../ProgressBar/ProgressGsap"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const AccueilContainer = styled.section`
  height: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #000000;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const AccueilWrapper = styled.main`
  width: 300%;
  height: 100vh;
  display: flex;
  flex-wrap: no-wrap;
`;

const AccueilGsap = () => {
  const accueilRef = useRef(null)


  const [innerWidth,setInnerWidth] = useState(undefined)

  // const getMaxWidth = (sections, maxWidth) => {
  //   console.log("avant foreach")
  //   console.log(sections)
  //   for (let i = 0; i < 8; i++) {
  //     maxWidth += sections[i].offsetWidth
  //   }
  //   initGsap(sections, maxWidth);
  //   checkLoadedVideo(sections, maxWidth);
  // }

  // const initGsap = (sections, maxWidth) => {
  //   gsap.to(sections, {
  //     x: () => `-${maxWidth - window.innerWidth}`,
  //     ease: "none",
  //     scrollTrigger: {
  //       trigger: accueilRef.current,
  //       pin: true,
  //       scrub: true,
  //       end: () => `+=${maxWidth}`,
  //       invalidateOnRefresh: true,
  //     },
  //   })

  //   gsap.to("progress", {
  //     value: 100,
  //     ease: "none",
  //     scrollTrigger: {
  //       scrub: 0.3,
  //       end: () => `+=${maxWidth}`,
  //     },
  //   })
  // }

  // const checkLoadedVideo = (sections, maxWidth) => {
  //   sections.forEach((sct) => {
  //     let videoElem = sct.querySelector(".video");
  //     if (videoElem) {
  //       videoElem.addEventListener("loadeddata", () => {
  //         ScrollTrigger.create({
  //           trigger: videoElem,
  //           start: () => "top top-=" + (sct.offsetLeft - (window.innerWidth - 150)) * (maxWidth / (maxWidth - window.innerWidth)),
  //           end: () => "+=" + sct.offsetWidth * (maxWidth / (maxWidth - window.innerWidth)),
  //           onEnter: () => { videoElem.play() },
  //           once: true,
  //         })
  //       })
  //     }
  //   })
  // }
const refreshInnerWidth =(value)=>{
setInnerWidth(value)
}

  useEffect(() => {
    if (accueilRef !== null) {
      
      const sections = gsap.utils.toArray(".accueilTrigger");
      sections.length = 8;
      let maxWidth = 0;

      const getMaxWidth = () => {
        maxWidth = 0;
        sections.forEach((section, index) => {
          if (section.id === "ombrea" || section.id === "scic") {
            maxWidth += section.offsetHeight * 0.7
            console.log(section.id)
          } else if (section.id === "accueil") {
            maxWidth = 0
            maxWidth += section.offsetWidth
            console.log(section.id)
          } else if (section.id === "filtresSct") {
            maxWidth += section.offsetWidth
            console.log(section.id)
          } else {
            maxWidth += section.offsetHeight * 1.5
            console.log(section.id)
          }
        })
      }

      // const timer = setTimeout(function(){ 
        getMaxWidth();
        ScrollTrigger.addEventListener("refreshInit", getMaxWidth);

        gsap.to(sections, {
          x: () => `-${maxWidth - window.innerWidth}`,
          ease: "none",
          scrollTrigger: {
            trigger: accueilRef.current,
            pin: true,
            scrub: 0.8,
            end: () => `+=${maxWidth}`,
            invalidateOnRefresh: true,
          },
        })

        sections.forEach((sct) => {
          let videoElem = sct.querySelector(".video");
          if (videoElem) {
            videoElem.addEventListener("loadeddata", () => {
              ScrollTrigger.create({
                trigger: videoElem,
                start: () => "top top-=" + (sct.offsetLeft - (window.innerWidth - 150)) * (maxWidth / (maxWidth - window.innerWidth)),
                end: () => "+=" + sct.offsetWidth * (maxWidth / (maxWidth - window.innerWidth)),
                onEnter: () => { videoElem.play() },
                once: true,
              })
            })
          }
        })
    
        gsap.to("progress", {
          value: 100,
          ease: "none",
          scrollTrigger: {
            scrub: 0.3,
            end: () => `+=${maxWidth}`,
          },
        })
      // }, 200);
      // return () => clearTimeout(timer);
    } 

    return () => { 
      accueilRef.current = null;
    }
  }, [])

  function gsapScrollTo() {
    gsap.to(window, {
      scrollTo: document.getElementById("filtresSct").offsetLeft + 0,
    })
  }


  return (
    <AccueilContainer id="start">
      <AccueilWrapper ref={accueilRef} id="homeContainer">
        <Accueil gsapScrollTo={() => gsapScrollTo()} refreshInnerWidth={() => refreshInnerWidth()} />
        <Filtres />

        <Ombrea />
        <Iketchak />
        <Scic />
        <GreenCode />
        <Drmc />
        <Sorgalu />
      </AccueilWrapper>

      <ProgressGsap />
    </AccueilContainer>
  )
}

export default AccueilGsap
