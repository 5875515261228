import { injectIntl } from "gatsby-plugin-intl"
import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

import mainVideo from "./video/Abstract.mp4"

import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/all"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import CookieGa from "../CookieConsent/CookieGa"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const AccueilContainer = styled.section`
  color: white;
  display: block;
  min-width: 100vw;
  width: 100vw;
  height: 100vh;
`

const AccueilSectionText = styled.article`
  z-index: 200;
  margin: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1em;
`

const AccueilTitle = styled.h1`
  font-size: 2.5rem;
  width: 100%;
  margin-bottom: 2rem;

  -webkit-animation: fadeinFlop ease-out 0.4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinFlop ease-out 0.4s; /* Firefox < 16 */
  -ms-animation: fadeinFlop ease-out 0.4s; /* Internet Explorer */
  -o-animation: fadeinFlop ease-out 0.4s; /* Opera < 12.1 */
  animation: fadeinFlop ease-out 0.4s;

  @media screen and (min-width: 768px) {
    font-size: 3rem;
    width: 60%;
  }
`

const AccueilSlogan = styled.h2`
  font-size: 14pt;
  margin-bottom: 20px;
  @media screen and (min-width: 768px) {
    width: 70%;
    @media screen and (min-width: 821px) {
      font-size: 1.34316rem !important;
    }
  }
`

const AccueilBtnSection = styled.article`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const AccueilBtn = styled.a`
  width: fit-content;
  padding: 0.2rem 0.6rem;
  border-radius: 1.75px;
  outline: none;
  border: none;
  text-decoration: none;

  color: rgba(255, 255, 255, 0.88);
  background-color: rgba(46, 47, 50, 0.88);

  font-family: "Perroquet-Regular", sans-serif;
  text-transform: uppercase;
  font-size: 10pt;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;

  transition: background-color 0.55s ease-in-out;

  &:hover {
    background-color: #7600ff;
  }
`

const VideoPerroquet = styled.video`
  width: 100vw;
  position: fixed;
  height: 100vh;
  object-fit: cover;
`

const Accueil = ({ intl, gsapScrollTo, refreshInnerWidth }) => {
  const pageTitle = useRef(null)
  const pageSubtitle = useRef(null)
  const pageBtnSect = useRef(null)
  // const scrollBtnAccueil = document.getElementById('scrollBtnAccueil');

  const viewportContext = createContext({})

  const ViewportProvider = ({ children }) => {
    const [width, setWidth] = useState(
      typeof window !== "undefined" ? window.innerWidth : undefined
    )

    const handleWindowRezise = () => {
      setWidth(window.innerWidth)
      refreshInnerWidth(window.innerWidth)
    }

    useEffect(() => {
      console.log(window.innerWidth)
      window.addEventListener("resize", handleWindowRezise)
      return () => window.removeEventListener("resize", handleWindowRezise)
    }, [])

    return (
      <viewportContext.Provider value={{ width }}>
        {children}
      </viewportContext.Provider>
    )
  }

  const useViewport = () => {
    const { width } = useContext(viewportContext)
    return { width }
  }

  const MobileInfos = () => (
    <AccueilBtn onClick={() => scrollTo("#infos")}>+</AccueilBtn>
  )
  const DesktopInfos = () => (
    <AccueilBtn onClick={() => scrollTo("#infos")}>
      {intl.formatMessage({ id: "decouvrirBureau" })}
    </AccueilBtn>
  )

  const MobileScroll = () => (
    <AccueilBtn id="scrollBtnAccueil" rel="noreferrer" onClick={gsapScrollTo}>
      &#8594;
    </AccueilBtn>
  )
  const DesktopScroll = () => (
    <AccueilBtn id="scrollBtnAccueil" rel="noreferrer" onClick={gsapScrollTo}>
      {intl.formatMessage({ id: "selectionProjet" })} &#8594;
    </AccueilBtn>
  )

  const ScrollToBureau = () => {
    const { width } = useViewport()
    const breakpoint = 768

    return width < breakpoint ? <MobileInfos /> : <DesktopInfos />
  }

  const ScrollToProjects = () => {
    const { width } = useViewport()
    const breakpoint = 768

    return width < breakpoint ? <MobileScroll /> : <DesktopScroll />
  }

  useEffect(() => {
    if (pageTitle !== null && pageSubtitle !== null && pageBtnSect !== null) {
      gsap.from(pageTitle.current, {
        x: -800,
        opacity: 0,
        ease: "slowMo",
        duration: 0.8,
      })
      gsap.from(
        pageSubtitle.current,
        { x: -800, opacity: 0, ease: "slowMo", duration: 0.8 },
        ">"
      )
      gsap.from(
        pageBtnSect.current,
        { y: 200, opacity: 0, ease: "slowMo", duration: 0.8 },
        ">"
      )
    }
  }, [])

  return (
    <AccueilContainer id="accueil" className="accueilTrigger">
      <AccueilSectionText>
        <AccueilTitle ref={pageTitle}>
          {intl.formatMessage({ id: "sloganSite" })}
        </AccueilTitle>
        <AccueilSlogan ref={pageSubtitle}>
          {intl.formatMessage({ id: "descriptionHeroSection" })}
        </AccueilSlogan>

        <AccueilBtnSection ref={pageBtnSect}>
          <ViewportProvider>
            <ScrollToBureau />
            <ScrollToProjects />
          </ViewportProvider>
        </AccueilBtnSection>
      </AccueilSectionText>
      <VideoPerroquet
        src={mainVideo + "#t=0.1"}
        autoPlay
        loop
        playsInline
        muted
      ></VideoPerroquet>

      <CookieGa />
    </AccueilContainer>
  )
}

export default injectIntl(Accueil)
