import { injectIntl, Link } from "gatsby-plugin-intl";
import React from "react";
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from "styled-components";

import sorgaluVideo from "../video/sorgalu-videoMiseEnAvant.mp4";
import sorgaluPoster from "../poster/sorgalu.png"

const VideoContainer = styled.section`
  height: 100vh;
  width: calc(100vh * 1.5);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  alig-items: flex-end;
  position: relative;
`;

const VideoContent = styled.video`
  width: auto;
  height: 100%;
`;

const SlideInfosContainer = styled.section`
  position: absolute;
  z-index: 99999999;
  bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

const SlideWrapper = styled.article`
  display: block;
`;

const SlideSlogan = styled.p`
  padding-left: 0.75em;
  padding-bottom: 0.5em;

  font-size: 1.38316rem !important;
  line-height: normal;
  color: #fff;
  -webkit-animation: fadeinShow ease-out 4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinShow ease-out 4s; /* Firefox < 16 */
  -ms-animation: fadeinShow ease-out 4s; /* Internet Explorer */
  -o-animation: fadeinShow ease-out 4s; /* Opera < 12.1 */
  animation: fadeinShow ease-out 4s;
`;

const GoToPage = styled(Link)`
  text-decoration: none;
  display: inline-block;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0.2rem 0.5rem !important;
  margin-left: 1.25em;
  margin-bottom: 0.5rem;
  background-color: #FFFFFF;
  color: #7600ff !important;
  align-self: flex-end;
  font-size: 12pt;
  border-radius: 1.75px;

  transition: background-color 0.75s ease, color 0.75s ease;
  &:hover {
    background-color: #7600ff;
    color: #FFFFFF !important;
  }
`;

const BtnContainer = styled.article`
  z-index: 120;
  padding-right: 1rem;
  padding-bottom: 1em;
`;

const BtnScroll = styled.article`
  background-color: rgba(46, 47, 50, 0.88);
  padding: 0.2rem 0.6rem;
  border-radius: 1.75px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 10pt;
  letter-spacing: 1px;
  font-family: "Perroquet-Bold", Arial, Helvetica, sans-serif;
  color: rgba(255, 255, 255, 0.88);
  text-transform: uppercase;

  cursor: pointer;
  transition: background-color 0.55s ease-in-out;

  &:hover {
    background-color: #7600ff;
  }
`;

const Sorgalu = ({ intl }) => {
  return (
    <VideoContainer className="accueilTrigger">
      <VideoContent src={sorgaluVideo + "#t=0.1"} playsInline muted onClick={e => e.target.play()} poster={sorgaluPoster} id="sorgalu" className="video">
      </VideoContent>

      <SlideInfosContainer>
        <SlideWrapper>
          <SlideSlogan>{intl.formatMessage({ id: "sorgaluSlog" })}</SlideSlogan>
          <GoToPage to="/sorgalu">Sorg'alu</GoToPage>
        </SlideWrapper>
        <BtnContainer>
          <BtnScroll onClick={() => scrollTo("#infos")}>
            <span>{intl.formatMessage({ id: "decouvrirBureau" })}</span>
          </BtnScroll>
        </BtnContainer>
      </SlideInfosContainer>
    </VideoContainer>
  )
}

export default injectIntl(Sorgalu)
