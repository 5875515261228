import { injectIntl } from 'gatsby-plugin-intl';
import React from 'react';
import styled from 'styled-components';

import Clement from '../../../img/profil/Clement-photo-de-profil.jpg';
import Corentin from '../../../img/profil/Corentin-photo-de-profil.jpg';
import Olivier from '../../../img/profil/Olivier-photo-de-profil.jpg';
import Emilie from '../../../img/profil/Emilie-photo-de-profil.jpg';
import Lucas from '../../../img/profil/Lucas-photo-de-profil.jpg';
import AncreHref from '../../Ancres/AncreHref';

const EquipeContainer = styled.section`
  width: 100%;
  background-color: transparent;
  margin-top: 80px;

  @media (min-width: 768px){
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 50px auto;

    h4 {
      grid-column: 1/3;
    }
  }
  @media (min-width: 950px){
    display: grid;
    grid-template-columns: repeat(3, 33.333%);
    grid-template-rows: 50px auto;

    h4 {
      grid-column: 1/4;
    }
  }

  @media (min-width: 1100px){
    min-width: 320px;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 5rem;
    margin-top: 0px;
  }
`

const EquipeTitle = styled.h4`
  letter-spacing: 1px;
  font-size: 10pt;
  font-family: "Perroquet-Regular",Arial, Helvetica, sans-serif;
  display: block;
  color: #777 !important;
`

const EquipeArticle = styled.article`
  width: 100%;
  display: flex;
  margin-bottom: 15px;

  @media (min-width: 820px){
    margin-bottom: 5px;
  }
`

const EquipePhotoProfil = styled.img`
  width: 66px;
  height: 66px;
  margin-right: 20px;
`

const EquipeInfosDiv = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.2rem;
`

const EquipeLink = styled.a`
  text-decoration: none;
  font-size: 13pt;
  color: white;
  margin-top: 6px;
`

const EquipeInfosText = styled.p`
  font-size: 13pt;
`


const Equipe = ({ intl }) => {
  return (
    <EquipeContainer>
      <EquipeTitle>{intl.formatMessage({ id: "équipe" })}</EquipeTitle>

      <EquipeArticle>
        <EquipePhotoProfil src={Clement} />
        <EquipeInfosDiv>
          <AncreHref url="https://www.linkedin.com/in/cl%C3%A9ment-catherine-96915052/">Clément CATHERINE</AncreHref>
          <EquipeLink href={`mailto:clement@perroquet.eu`}>clement@perroquet.eu</EquipeLink>
          <EquipeInfosText>{intl.formatMessage({ id: "directeurArtistique" })}</EquipeInfosText>
        </EquipeInfosDiv>
      </EquipeArticle>

      <EquipeArticle>
        <EquipePhotoProfil src={Corentin} />
        <EquipeInfosDiv>
        <AncreHref url="https://www.linkedin.com/in/corentin-deydier-821b06115/">Corentin DEYDIER</AncreHref>
          <EquipeLink href={`mailto:corentin@perroquet.eu`}>corentin@perroquet.eu</EquipeLink>
          <EquipeInfosText>{intl.formatMessage({ id: "leadDeveloppeur" })}</EquipeInfosText>
        </EquipeInfosDiv>
      </EquipeArticle>

      <EquipeArticle>
        <EquipePhotoProfil src={Olivier} />
        <EquipeInfosDiv>
        <AncreHref url="https://www.linkedin.com/in/olivier-catherine-71028b19/">Olivier CATHERINE</AncreHref>
          <EquipeLink href={`mailto:olivier@perroquet.eu`}>olivier@perroquet.eu</EquipeLink>
          <EquipeInfosText>{intl.formatMessage({ id: "businessDevelopper" })}</EquipeInfosText>
        </EquipeInfosDiv>
      </EquipeArticle>

      <EquipeArticle>
        <EquipePhotoProfil src={Emilie} />
        <EquipeInfosDiv>
        <AncreHref url="https://www.linkedin.com/in/emilie-spina-a727521b8/">Émilie SPINA</AncreHref>
          <EquipeLink href={`mailto:emilie@perroquet.eu`}>emilie@perroquet.eu</EquipeLink>
          <EquipeInfosText>{intl.formatMessage({ id: "développeurFrontEnd" })}</EquipeInfosText>
        </EquipeInfosDiv>
      </EquipeArticle>

      <EquipeArticle>
        <EquipePhotoProfil src={Lucas} />
        <EquipeInfosDiv>
        <AncreHref url="https://www.linkedin.com/in/lucas-pierre-0154b6210/">Lucas PIERRE</AncreHref>
          <EquipeLink href={`mailto:lucas@perroquet.eu`}>lucas@perroquet.eu</EquipeLink>
          <EquipeInfosText>{intl.formatMessage({ id: "cheffe" })}</EquipeInfosText>
        </EquipeInfosDiv>
      </EquipeArticle>

    </EquipeContainer>
  )
}

export default injectIntl(Equipe)