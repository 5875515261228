import { injectIntl } from "gatsby-plugin-intl"
import React from 'react'
import styled from "styled-components"

const AvisBg = styled.section`
  background-color: #7600FF50;
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;

  transition: opacity 0.75s ease;
  opacity: ${({ avisBackground }) => (avisBackground ? "100%" : "0")};
  height: ${({ avisBackground }) => (avisBackground ? "100%" : "0")};
  bottom: ${({ avisBackground }) => (avisBackground ? "0" : "100%")};
  transform: ${({ avisBackground }) => avisBackground ? "translateY(0)" : "translateY(100%)"};

  z-index: 1;
`

const AvisSection = styled.aside`
  width: 90vw;
  height: 83vh;

  position: fixed;
  top: 6rem;
  left: 5vw;

  background-color: white;
  color: black !important;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5%;

  transition-property: opacity, bottom, transform;
  transition-duration: 0.75s;
  transition-timing-function: ease;

  opacity: ${({ avisIsOpen }) => (avisIsOpen ? "100%" : "0")};
  bottom: ${({ avisIsOpen }) => (avisIsOpen ? "3rem" : "100%")};
  transform: ${({ avisIsOpen }) => avisIsOpen ? "translateY(0)" : "translateY(100%)"};
`

const MainText = styled.p`
  color: black !important;
  margin-bottom: 60px;
  font-size: 1rem;
  line-height: 1.3rem;

  @media screen and (min-width: 820px){
    font-size: 1.35rem;
    line-height: 1.8rem;
  }
`

const Client = styled.p`
  color: black !important;
  font-size: 1rem;
  line-height: 1.3rem;

  @media screen and (min-width: 820px){
    font-size: 1.35rem;
    line-height: 1.8rem;
  }
`

const Avis = ({ intl, avisBackground, avisIsOpen, toggleAvis }) => {
  return (
    <AvisBg avisBackground={avisBackground}>
      <AvisSection avisIsOpen={avisIsOpen} toggleAvis={toggleAvis}>
        <MainText>{intl.formatMessage({ id: "jaiAppre" })}</MainText>
        <Client>{intl.formatMessage({ id: "anneDelaval" })}</Client>
      </AvisSection>
    </AvisBg>
  )
}

export default injectIntl(Avis)