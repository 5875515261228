import React from 'react'
import styled from 'styled-components'

import FrencTechLogo from "../../../img/partenaires/Perroquet-site-web-membre-french-tech.jpg"
import CEELogo from "../../../img/partenaires/Perroquet-site-web-membre-cite-entrepreneurs-euromediterranee.jpg"
import MedinsoftLogo from "../../../img/partenaires/Perroquet-site-web-membre-medinsoft.jpg"
import MarseilleInnovLogo from "../../../img/partenaires/Perroquet-site-web-membre-marseille-innovation.jpg"


const ReferencesrContainer = styled.article`
  width: 100%;
  margin-bottom: 1.5rem;

  @media screen and (min-width: 820px){
    margin-bottom: 0rem;
  }
`;

const ReferencesTitle = styled.h4`
  letter-spacing: 1px;
  font-size: 10pt;
  font-family: "Perroquet-Regular",Arial, Helvetica, sans-serif;
  display: block;
  color: #777 !important;
  text-transform: uppercase;
  padding-top: 80px;

  @media screen and (min-width: 768px) {
    padding-top: 20px;
  }
`;

const ReferencesImgWrapper = styled.section`
  width: 100%;
`;

const ReferencesImgLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

const ReferencesImg = styled.img`
  width: clamp( 110px, 8vw, 160px);
  height: auto;
  margin: 0 20px 20px 0;
  border: 0px solid #7600ff;
  transition: transform 0.8s ease, border 0.8s ease;

  &:hover {
    transform: scale(1.1);
    border: 2px solid #7600ff;
  }
`;

const References = () => {

  return (
    <ReferencesrContainer>
      <ReferencesTitle>Perroquet est membre de</ReferencesTitle>
      <ReferencesImgWrapper>
        <ReferencesImgLink href="https://lafrenchtech.com/fr/" rel="noreferrer" target="_blank">
          <ReferencesImg src={FrencTechLogo} alt="La French Tech" />
        </ReferencesImgLink>
        
        <ReferencesImgLink href="https://la-cite.com/" rel="noreferrer" target="_blank">
          <ReferencesImg src={CEELogo} alt="La Cité des Entrepreneurs d'Euroméditerranée" />
        </ReferencesImgLink>

        <ReferencesImgLink href="https://medinsoft.com/" rel="noreferrer" target="_blank">
          <ReferencesImg src={MedinsoftLogo} alt="Medinsoft" />
        </ReferencesImgLink>

        <ReferencesImgLink href="https://marseille-innov.org/" rel="noreferrer" target="_blank">
          <ReferencesImg src={MarseilleInnovLogo} alt="Marseille Innovation" />
        </ReferencesImgLink>
      </ReferencesImgWrapper>
    </ReferencesrContainer>
  )
}

export default References
