import React from 'react';
import styled from 'styled-components';

const BureauNavSection = styled.section`
  padding: 0.5rem;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  background-color: transparent;
`

const BureauNavContainer = ({ children }) => {
  return (
    <BureauNavSection id="sousMenu">
      {children}
    </BureauNavSection>
  )
}

export default BureauNavContainer
