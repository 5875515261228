import { injectIntl } from "gatsby-plugin-intl";
import React from "react";
import styled from "styled-components";
import * as AnimationStyle from '../../animations.module.scss';

const BureauNav = styled.article`
  display: flex;
  position: absolute;
  top: 3rem;
  left: 0.5rem;
  

  z-index: 1200;
`;

const ApprocheBtn = styled.button`
  display: block;
  border: 1px solid #777;
  background-color: rgba(0, 0, 0, 0.88);
  padding: 0.2rem 0.6rem;
  border-radius: 30px;
  margin-right: 0.2rem;
  font-size: 10pt;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.88);
  cursor: pointer;

  transition: all .5s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.88) !important;
    color: black;
    border: 1px solid #e0e0e0 !important;
  }

  &:active {
    background-color: #7600ff !important;
    border: none !important;
    font-weight: bold;
    color: white !important;
  }

  &:focus {
    background-color: #7600ff !important;
    color: white !important;
    border: none !important;
    box-shadow: none;
    font-weight: bold;
  }
`;

const HeaderBureau = ({ intl, toggle, toggleAvis }) => {
  return (
    <BureauNav className={AnimationStyle.fadeNavBar}>
      <ApprocheBtn onClick={toggle}>{intl.formatMessage({ id: "approche" })}</ApprocheBtn>
      <ApprocheBtn onClick={toggleAvis}>{intl.formatMessage({ id: "avisClient" })}</ApprocheBtn>
    </BureauNav>
  )
}

export default injectIntl(HeaderBureau)