import React, { useState } from 'react';
import Approche from './Modals/Approche';
import Avis from './Modals/Avis';
import HeaderBureau from './HeaderBureau';

const BureauNavToggle = () => {

  const [background, setBackground] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [avisBackground, setAvisBackground] = useState(false);
  const [avisIsOpen, setAvisIsOpen] = useState(false);

  const toggle = () => { 
    setIsOpen(!isOpen);
    setBackground(!background);
    setAvisIsOpen(false);
    setAvisBackground(false);

    if(isOpen === false) {
      document.body.style.overflowY = "hidden";
      // document.getElementById('start').style.display = "none";
      // document.getElementById('bureauSection').scrollIntoView({behavior: "instant", block: "start"});
    } else {
      document.body.style.overflowY = "";
      // document.getElementById('start').style.display = "";
      // document.getElementById('bureauSection').scrollIntoView({behavior: "instant", block: "start"});
    }
  }

  const toggleAvis = () => { 
    setAvisIsOpen(!avisIsOpen)
    setAvisBackground(!avisBackground)
    setIsOpen(false)
    setBackground(false)

    if(avisIsOpen === false) {
      document.body.style.overflowY = "hidden";
      // document.getElementById('start').style.display = "none";
      // document.getElementById('bureauSection').scrollIntoView({behavior: "instant", block: "start"});
    } else {
      document.body.style.overflowY = "";
      // document.getElementById('start').style.display = "";
      // document.getElementById('bureauSection').scrollIntoView({behavior: "instant", block: "start"});
    }
  }

  return (
    <>
      <HeaderBureau toggle={toggle} toggleAvis={toggleAvis} />
      <Approche id="approche" background={background} isOpen={isOpen} toggle={toggle}/>
      <Avis avisBackground={avisBackground} avisIsOpen={avisIsOpen} toggleAvis={toggleAvis} />
    </>
  )
}

export default BureauNavToggle
