import { injectIntl, Link } from "gatsby-plugin-intl";
import React from "react";
import styled from "styled-components";

import scicVideo from "../video/scic-videoMiseEnAvant.mp4";
import scicPoster from "../poster/scic.png"

const VideoContainer = styled.section`
  height: 100vh;
  width: calc(100vh * 0.7);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  alig-items: flex-end;
  positon: relative
`;

const VideoContent = styled.video`
  width: auto;
  height: 100%;
`;

const SlideInfosContainer = styled.section`
  position: absolute;
  z-index: 99999999;
  bottom: 1rem;
  display: block;
  justify-content: space-between;
  width: 100%;
`;

const SlideSlogan = styled.p`
  padding-left: 0.75em;
  padding-bottom: 0.5em;

  font-size: 1.38316rem !important;
  line-height: normal;
  color: #fff;
  -webkit-animation: fadeinShow ease-out 4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinShow ease-out 4s; /* Firefox < 16 */
  -ms-animation: fadeinShow ease-out 4s; /* Internet Explorer */
  -o-animation: fadeinShow ease-out 4s; /* Opera < 12.1 */
  animation: fadeinShow ease-out 4s;
`;

const GoToPage = styled(Link)`
  text-decoration: none;
  display: inline-block;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0.2rem 0.5rem !important;
  margin-left: 1.25em;
  margin-bottom: 0.5rem;
  background-color: #FFFFFF;
  color: #7600ff !important;
  align-self: flex-end;
  font-size: 12pt;
  border-radius: 1.75px;

  transition: background-color 0.75s ease, color 0.75s ease;
  &:hover {
    background-color: #7600ff;
    color: #FFFFFF !important;
  }
`;

const Scic = ({ intl }) => {
  return (
    <VideoContainer id="scic" className="accueilTrigger">
      <VideoContent src={scicVideo + "#t=0.1"} playsInline muted onClick={e => e.target.play()} poster={scicPoster} id="scic" className="video">
      </VideoContent>

      <SlideInfosContainer>
        <SlideSlogan>{intl.formatMessage({ id: "scicSlog" })}</SlideSlogan>
        <GoToPage to="/scic">SCIC</GoToPage>
      </SlideInfosContainer>
    </VideoContainer>
  )
}

export default injectIntl(Scic)