import { injectIntl } from "gatsby-plugin-intl"
import React from "react"
import styled from "styled-components"

import firstBg from '../../../../img/images-bureau/Perroquet-poster.jpg';
import secondBg from '../../../../img/images-bureau/Poster-Perroquet-fold-1.jpg';
import thirdBg from '../../../../img/images-bureau/Poster-Perroquet-fold-3.jpg';

const ApprocheBg = styled.section`
  background-color: #7600FF50;
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;

  transition: opacity 0.75s ease;
  opacity: ${({ background }) => (background ? "1" : "0")};
  height: ${({ background }) => (background ? "100%" : "0")};
  bottom: ${({ background }) => (background ? "0" : "100%")};
  transform: ${({ background }) => background ? "translateY(0)" : "translateY(100%)"};

  z-index: 1;
`

const ApprocheSection = styled.aside`
  width: 90vw;
  height: 83vh;

  position: fixed;
  top: 6rem;
  left: 5vw;

  transition-property: opacity, bottom, transform;
  transition-duration: 0.75s;
  transition-timing-function: ease;

  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  bottom: ${({ isOpen }) => (isOpen ? "3rem" : "100%")};
  transform: ${({ isOpen }) => isOpen ? "translateY(0)" : "translateY(100%)"};

  overflow-y: scroll !important;
  scroll-snap-type: y mandatory;

  z-index: 1000;
`

const ApprocheArticle = styled.section`
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
  scroll-behavior: smooth;

  #firstBg {
    background-image: url('${firstBg}');
    background-size: cover;
    background-position: center center;
  }

  #secondBg {
    background-image: url('${secondBg}');
    background-size: cover;
    background-position: center center;
  }

  #thirdBg {
    background-image: url('${thirdBg}');
    background-size: cover;
    background-position: center center;
  }

  @media screen and (min-width: 820px){
    flex-direction: row-reverse;
  }
`

const ApprocheImg = styled.article`
  height: 41.5vh;
  width: 90vw;

  @media screen and (min-width: 820px){
    height: 83vh;
    width: 45vw;
  }
`

const TextSpanContainer = styled.article`
  width: 100%;
  min-height: 41.5vh;
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4%;

  @media screen and (min-width: 820px){
    height: 83vh;
    width: 45vw;
    padding: 0 6%;
  }
`

const TextSpan = styled.span`
  color: black;
  font-size: 1rem;
  line-height: 1.3rem;

  @media screen and (min-width: 820px){
    font-size: 1.35rem;
    line-height: 1.8rem;
  }
`

const Approche = ({ background, isOpen, toggle, intl }) => {

  return (
    <ApprocheBg background={background}>
    <ApprocheSection isOpen={isOpen} toggle={toggle}>

      <ApprocheArticle>
        <ApprocheImg id="firstBg"></ApprocheImg>
        <TextSpanContainer>
          <TextSpan>{intl.formatMessage({ id: "nosDomaines" })}</TextSpan>
        </TextSpanContainer>
      </ApprocheArticle>

      <ApprocheArticle>
        <ApprocheImg id="secondBg"></ApprocheImg>
        <TextSpanContainer>
          <TextSpan>{intl.formatMessage({ id: "nousFaisons" })}</TextSpan>
        </TextSpanContainer>
      </ApprocheArticle>

      <ApprocheArticle>
        <ApprocheImg id="thirdBg"></ApprocheImg>
        <TextSpanContainer>
          <TextSpan>{intl.formatMessage({ id: "nousPrivi" })}</TextSpan>
          <TextSpan>{intl.formatMessage({ id: "notreVision" })}</TextSpan>
        </TextSpanContainer>
      </ApprocheArticle>
     
    </ApprocheSection>
    </ApprocheBg>
  )
}

export default injectIntl(Approche)