import { injectIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

const BureauContainer = styled.article`
  width: 100%;
`;

const BureauTitle = styled.h4`
  letter-spacing: 1px;
  font-size: 10pt;
  font-family: "Perroquet-Regular",Arial, Helvetica, sans-serif;
  display: block;
  color: #777 !important;
`;

const BureauText = styled.p`
  color: rgba(255, 255, 255, 0.88);
  font-size: 14pt !important;
  padding: 0 0 1.5rem 0;
  line-height: normal;
  font-family: "Perroquet-Regular", Arial, Helvetica, sans-serifs;

  @media screen and (min-width: 821px){
    font-size: 1.34316rem !important;
  }
  @media screen and (min-width: 1200px){
    padding: 0 4rem 1.5rem 0;
    font-size: 1.34316rem !important;
  }
`;

const Bureau = ({ intl }) => {
  return (
    <BureauContainer>
      <BureauTitle>PRÉSENTATION</BureauTitle>
      <BureauText>{intl.formatMessage({ id: "bigInfos" })}</BureauText>
      <BureauText>{intl.formatMessage({ id: "bigInfos1" })}</BureauText>
    </BureauContainer>
  )
}

export default injectIntl(Bureau)