import { injectIntl } from "gatsby-plugin-intl";
import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";

import "../components/animations.module.scss";


import Preloader from "../components/Preloader/Preloader";
import Seo from "../components/seo-old.js";
import HeaderNav from "../components/Header/HeaderNav";
import AccueilGsap from "../components/Index/AccueilGsap";
import PageBureau from "../components/Bureau/PageBureau";


const IndexPage = ({ intl, location }) => {

  // const [preloader, setPreloader] = useState(true);

  const data = useStaticQuery(graphql`
  query {
    profils: allFile(filter: { sourceInstanceName: { eq: "profils" } }) {
      totalCount
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1200,quality:50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    seo: allFile(filter: { sourceInstanceName: { eq: "seo" } }) {
      totalCount
      edges {
        node {
          childImageSharp{
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
    fonds: allFile(filter: { sourceInstanceName: { eq: "fond" } }) {
      totalCount
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2560,quality:50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  `)

  function checkProps(location) {
    let stateo = { anchor: '#start' }
    if (location.state === null) {
      location.state = stateo
    }
  }
  checkProps(location)

  // useEffect(() => {
  //   setTimeout(() => {
  //     setPreloader(false);
  //     window.sessionStorage.setItem('loader', "false");
  //   }, 3500)
  // }, [])

  // const isBrowser = typeof window !== "undefined"
  // if(isBrowser){
  //   var localLoader = window.sessionStorage.getItem('loader');
  // }

  const image = data.seo.edges[0] ? data.seo.edges[1].node.childImageSharp.resize : null

  return (
    <React.Fragment>
      <Seo
        lang={intl.formatMessage({ id: "lang" })}
        pathname={location.pathname}
        title={intl.formatMessage({ id: "titreMetadata" })}
        description={intl.formatMessage({ id: "descriptionMetadataAccueil" })}
        keywords={intl.formatMessage({ id: "keywordsMetadataAccueil" })}
        imageStatic={"https://perroquet.eu/static/922fd8a6882d9b868bc2df5070c57f8b/47498/Perroquet-3D-SEO-Card.jpg"}
        imageWidth={"1200"}
        imageHeight={"791"}
        image={image}
      />


      <meta itemprop="image" content={"https://perroquet.eu/static/922fd8a6882d9b868bc2df5070c57f8b/47498/Perroquet-3D-SEO-Card.jpg"} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={location.pathname} />
      <meta property="og:title" content={intl.formatMessage({ id: "titreMetadata" })} />
      <meta name="image" property="og:image" content={"https://perroquet.eu/static/922fd8a6882d9b868bc2df5070c57f8b/47498/Perroquet-3D-SEO-Card.jpg"} />
      <meta property="og:description" content={intl.formatMessage({ id: "descriptionMetadataAccueil" })} />

      <meta name="twitter:card" content={"https://perroquet.eu/static/922fd8a6882d9b868bc2df5070c57f8b/47498/Perroquet-3D-SEO-Card.jpg"} />


      <HeaderNav />

      <AccueilGsap />

      <PageBureau />
    </React.Fragment>
  )
}

export default injectIntl(IndexPage)