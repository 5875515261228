import { injectIntl, Link } from "gatsby-plugin-intl"
import React from "react"
import styled from "styled-components"

import ImageFondIntro from "../../img/fond/perroquet-fond-categories-intro.jpg"

const FiltreFlex = styled.section`
  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  background-image: url(${ImageFondIntro});
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: 768px) {
    width: 768px;
    min-width: 768px;
  }
`

const FiltreContainer = styled.article`
  min-width: 100%;
  height: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;

  font-family: "Perroquet-regular", Arial, Helvetica, sans-serif;
`

const FiltreArticle = styled.section`
  width: 100%;
  padding: 0 1rem;
`

const FiltreTitle = styled.h3`
  color: #fff;
`

const FiltreLinkArticle = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 0.5rem;
  animation-delay: 2s;
  -webkit-animation: fadeinFlip ease-out 0.4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinFlip ease-out 0.4s; /* Firefox < 16 */
  -ms-animation: fadeinFlip ease-out 0.4s; /* Internet Explorer */
  -o-animation: fadeinFlip ease-out 0.4s; /* Opera < 12.1 */
  animation: fadeinFlip ease-out 0.4s;
`

const FiltreLink = styled(Link)`
  display: block;
  border: 1px solid #777;
  background-color: rgba(0, 0, 0, 0.88);
  border-radius: 1rem;

  font-size: 10pt;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.88);

  margin: 0.2rem 0.2rem;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;

  transition-property: color, background-color, border;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.88);
    color: black;
    border: 1px solid #e0e0e0;
  }

  &:active {
    background-color: #7600ff;
    border: none;
    font-weight: bold;
    color: white;
  }

  &:focus {
    background-color: #7600ff;
    color: white;
    border: none;
    box-shadow: none;
    font-weight: bold;
  }
`


const Filtres = ({ intl }) => {
  return (
    <FiltreFlex className="accueilTrigger" id="filtresSct">
      <FiltreContainer id="filtres">
      
        <FiltreArticle>
          <FiltreTitle>{intl.formatMessage({ id: "domaines" })}</FiltreTitle>
          <FiltreLinkArticle>
            <FiltreLink
              to="/portfolio/#identiteVisuelle"
              title={"renvoie vers le portefolio trié par identité Visuelle"}
              state={{ filtre: "Identité visuelle" }}
            >
              {intl.formatMessage({ id: "identitéVisuelle" })}
            </FiltreLink>

            <FiltreLink
              to="/portfolio/#siteWeb"
              title={"renvoie vers le portefolio trié par Site Web"}
              state={{ filtre: "Site Web" }}
            >
              {intl.formatMessage({ id: "siteWeb" })}
            </FiltreLink>

            <FiltreLink
              to="/portfolio/#applicationMobile"
              title={"renvoie vers le portefolio trié par Application mobile"}
              state={{ filtre: "Application mobile" }}
            >
              {intl.formatMessage({ id: "applicationMobile" })}
            </FiltreLink>

            <FiltreLink
              to="/portfolio/#illustration"
              title={"renvoie vers le portefolio trié par Illustration"}
              state={{ filtre: "Illustration" }}
            >
              {intl.formatMessage({ id: "illustration" })}
            </FiltreLink>

            <FiltreLink
              to="/portfolio/#animation"
              title={"renvoie vers le portefolio trié par Animation"}
              state={{ filtre: "Animation" }}
            >
              {intl.formatMessage({ id: "animation" })}
            </FiltreLink>

            <FiltreLink
              to="/portfolio/#3D"
              title={"renvoie vers le portefolio trié par 3D"}
              state={{ filtre: "3D" }}
            >
              {intl.formatMessage({ id: "3D" })}
            </FiltreLink>
          </FiltreLinkArticle>
        </FiltreArticle>

        <FiltreArticle>
          <FiltreTitle>{intl.formatMessage({ id: "secteur" })}</FiltreTitle>
          <FiltreLinkArticle>
            <FiltreLink
              to="/portfolio/#environement"
              title={"renvoie vers le portefolio trié par Environnement"}
              state={{ sector: "Environnement" }}
            >
              {intl.formatMessage({ id: "environnement" })}
            </FiltreLink>

            <FiltreLink
              to="/portfolio/#sante"
              title={"renvoie vers le portefolio trié par Santé"}
              state={{ sector: "Santé" }}
            >
              {intl.formatMessage({ id: "santé" })}
            </FiltreLink>

            <FiltreLink
              to="/portfolio/#culture"
              title={"renvoie vers le portefolio trié par Culture"}
              state={{ sector: "Culture" }}
            >
              {intl.formatMessage({ id: "culture" })}
            </FiltreLink>

            <FiltreLink
              to="/portfolio/#immobilier"
              title={"renvoie vers le portefolio trié par Immobilier"}
              state={{ sector: "Immobilier" }}
            >
              {intl.formatMessage({ id: "immobilier" })}
            </FiltreLink>

            <FiltreLink
              to="/portfolio/#juridique"
              title={"renvoie vers le portefolio trié par Juridique"}
              state={{ sector: "Juridique" }}
            >
              {intl.formatMessage({ id: "juridique" })}
            </FiltreLink>

            <FiltreLink
              to="/portfolio/#evenementiel"
              title={"renvoie vers le portefolio trié par Evenementiel"}
              state={{ sector: "Evenementiel" }}
            >
              {intl.formatMessage({ id: "evenementiel" })}
            </FiltreLink>

            <FiltreLink
              to="/portfolio/#restauration"
              title={"renvoie vers le portefolio trié par Restauration"}
              state={{ sector: "Restauration" }}
            >
              {intl.formatMessage({ id: "restauration" })}
            </FiltreLink>
          </FiltreLinkArticle>
        </FiltreArticle>
      
        
      </FiltreContainer>
      </FiltreFlex>
  )
}

export default injectIntl(Filtres)
