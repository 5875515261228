import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Bureau from './Composants/Bureau';
import Equipe from './Composants/Equipe';
import ScrollToTopBtn from '../Footer/ScrollToTopBtn';
import FooterInfos from '../Footer/Footer';
import BureauNavContainer from './Navbar/BureauNavContainer';
import BureauNavToggle from './Navbar/BureauNavToggle';

import * as indexStyles from './bureau.module.scss';
import References from './Composants/References';

import ImageFond from "../../img/fond/Perroquet-fond.jpg"

const BureauContainer = styled.section`
  width: 100vw;
  height: auto;
  min-height: 101vh;
  background-image: url(${ImageFond}) !important;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`

const SectionBureau = styled.section`
  width: 100%;
  padding: 0 2rem 0 1rem;
  padding-top: 5rem;

  @media screen and (min-width: 820px){
    display: flex;
    flex-direction: row;
  }
`

const ArticleBureau = styled.article`
  width: 100%;
  
  @media screen and (min-width: 1100px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const ArticleBureauWrapper = styled.section`
  display: flex;
  flex-direction: column;
`

const PageBureau = () => {

  const [showTabs, setShowTabs] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.querySelector("#infos")) {
        if (document.querySelector("#infos").getBoundingClientRect().top >= 0) {
          document.getElementById("sousMenu").classList.remove(indexStyles.scroll)
          document.getElementById("btnBureau").classList.remove(indexStyles.colored)
          setShowTabs(false)
        }
        if (showTabs !== true) {
          if ((window.innerHeight + document.documentElement.scrollTop) >= document.body.scrollHeight) {
            // you're at the bottom of the page
            document.getElementById("sousMenu").classList.add(indexStyles.scroll)
            document.getElementById("btnBureau").classList.add(indexStyles.colored)
            setShowTabs(true)
          } else if (document.querySelector("#infos").getBoundingClientRect().top <= 0) {
            document.getElementById("sousMenu").classList.add(indexStyles.scroll)
            document.getElementById("btnBureau").classList.add(indexStyles.colored)
            setShowTabs(true)
          }
        }
      }
    })
  }, [showTabs])
  

  return (
    <BureauContainer id="infos">
      <BureauNavContainer>
        {showTabs === true && <BureauNavToggle />}
      </BureauNavContainer>

      <SectionBureau id="bureauSection">
        <ArticleBureau>
          <ArticleBureauWrapper>
            <Bureau />
            <References />
          </ArticleBureauWrapper>
          <Equipe />
        </ArticleBureau>
      </SectionBureau>

      <ScrollToTopBtn clicked={"#start"} />
      <FooterInfos />
    </BureauContainer>
  )
}

export default PageBureau
