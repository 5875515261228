import React from 'react';
import CookieConsent from "react-cookie-consent";
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useLocation } from "@reach/router"

export default function CookieGa() {

  const localisation = useLocation();

  return (
    <CookieConsent
    location="bottom"
    cookieName="gatsby-gdpr-google-analytics"
    style={{ background: "#FFFFFF", display: "flex", alignItems: "center" }}
    contentStyle={{ 
      color: "#000000",
      fontSize: "10pt",
      lineHeight: "12pt",
    }}
    buttonText="Accepter"
    buttonStyle={{ 
      color: "#FFFFFF",
      outline: "none",
      border: "none",
      backgroundColor: "#7600ff",
      textTransform: "uppercase",
      padding: "2px 10px",
      borderRadius: "0.2rem",
      fontSize: "11pt",
      marginLeft: "10px",
      cursor: "pointer",
    }}
    enableDeclineButton
    declineButtonText="Refuser"
    declineButtonStyle={{ 
      color: "#000000",
      outline: "none",
      border: "none",
      background: "transparent",
      backgroundColor: "transparent",
      fontSize: "11pt",
      width: "fit-content",
      fontFamily: "Perroquet-Bold",
      boxShadow: "inset 0 -1px 0 #7600ff, 0 1px 0 #7600ff",
      cursor: "pointer",
    }}
    expires={150}
    onAccept={() => { initializeAndTrack(localisation) }}
    hideOnAccept
    >
    Nous utilisons des cookies sur notre site web pour vous offrir l'expérience la plus pertinente en mémorisant vos préférences et en répétant vos visites. En cliquant sur &laquo; Tout accepter &raquo; vous consentez à l'utilisation de TOUS les cookies. Cependant, vous pouvez visiter les &laquo; Paramètres des cookies &raquo; pour fournir un consentement contrôlé.
    </CookieConsent>
  )
}
